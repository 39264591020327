@import "./variables";

.project-section {
  .container {
    width: 100%;
    height: 100%;
    @include flex-center(center, center);
    flex-direction: column;
    .project-section_title {
      text-transform: capitalize;
      width: 100%;
      text-align: left;
      font-family: $font-family;
      padding-bottom: 1rem;
    }
    .see-code a svg {
      height: 1.5rem;
      width: 1.5rem;
      margin-right: 1rem;
      color: #333;
      transition: all 0.3s;
      &:hover {
        color: rgba(51, 51, 51, 0.8);
      }
    }
    .project-information {
      .project-description,
      .project-stack {
        font-family: $content-font-family;
      }
      .demo-login {
        ul {
          li {
            list-style: none;
          }
        }
      }
    }

    .project {
      display: flex;
      justify-content: space-evenly;
    }
    .project-title {
      font-size: 2.5rem;
      margin: 1rem 0;
      font-family: $font-family;
    }

    .project:nth-child(2) {
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 3rem;
      position: relative;
      .img-thumbnail {
        height: 100%;
      }

      & div:last-child {
        height: 80%;
        border-radius: 1rem;
        .project-stack {
          display: inline-block;
        }
      }
    }
    .project:nth-child(3) {
      justify-content: flex-end;
      width: 100%;
      position: relative;
      img {
        height: 100%;
      }

      & div:last-child {
        height: 70%;
        border-radius: 1rem;
        .project-stack {
          display: inline-block;
        }
      }
    }
  }
}
.project-item {
  position: relative;
  width: 50%;
  z-index: 0;
  .img-thumbnail {
    border: none;
    border-radius: 0;
    padding: 0;
  }
}
