@import "./variables";

.contact-section {
  .contact-information {
    @include flex-center(center, center);
    flex-direction: column;
    width: 100%;
    padding: 0;
    .contact-description {
      font-size: 1.4rem;
      margin-bottom: 1.5rem;
      font-family: $content-font-family;
      text-align: center;
    }
  }

  .social-link-container {
    @include flex-center(center, center);

    .social a {
      svg {
        margin-right: 1.3rem;
        color: #333;
        &:hover {
          opacity: 0.5;
          transform: scale(1.125);
        }
      }
    }
  }
}
