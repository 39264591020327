@import "../variables";
@media screen and (min-width: 768px) {
  // intro section
  .intro-section {
    height: 650px;
    .intro-info {
      min-width: 100%;
      .intro {
        font-size: 2.8rem;
        padding: 1rem 4rem;
        text-align: center;
        letter-spacing: 2px;
      }
      .description {
        display: inline-block;
        font-size: 2rem;
        min-width: 100%;
        text-align: center;
        letter-spacing: 0.5px;
        background: $white-color;
        color: #333;
      }
    }
  }

  //   skills section
  .about-section {
    .container {
      min-width: 100%;
      .about-title {
        font-size: 4rem;
        letter-spacing: 0.5px;
      }
      .about-columns {
        grid-template-columns: repeat(6, 1fr);
        .about-description {
          margin-right: 0;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
        .skills {
          ul {
            padding: 0;

            li {
              @include flex-center(center, center);
            }
          }
        }
      }
    }
  }

  //   project section
  .project-section {
    padding: 60px 0;
    .container {
      min-width: 100%;
    }
    .project-section_title {
      font-size: 4rem;
    }
    .project {
      @include flex-direction(column);
      width: 100%;
      background: $white-color;
      padding: 2rem;
      margin-bottom: 2rem;
      .project-title {
        text-align: center;
        margin: 0;
      }
      .see-code {
        margin: 1rem 0;
        @include flex-center(space-between, center);
      }
      .project-item {
        width: 100%;
      }
    }
  }

  //   contact section
  .contact-section {
    .container {
      .contact-information {
        height: 50%;
        @include flex-center(center, center);
        flex-direction: column;
      }
      .contact-title {
        font-size: 3rem;
        text-align: center;
      }
      .contact-description {
        padding: 0;
        width: 90%;
      }
      .social-link-container {
        height: 10%;
        .social a {
          svg {
            height: 30px;
            width: 30px;
          }
        }
      }
    }
  }
}
