@mixin flex {
  display: flex;
}

@mixin flex-center($justify, $align) {
  @include flex;
  justify-content: $justify;
  align-items: $align;
}

@mixin flex-direction($direction) {
  @include flex;
  flex-direction: $direction;
}

// scss variables
$font-family: "Merriweather", serif;
$content-font-family: "Roboto", sans-serif;
$white-color: #fff;
$dark-color: #333;
