@import "./variables";

.footer-body {
  background-color: #333;
  color: $white-color;
  padding: 2rem;
  font-size: 1.1rem;
  font-family: $content-font-family;
  text-transform: capitalize;
  text-align: center;
  margin: 0;
}
