h1 {
  font-family: $font-family;
}
.img-thumbnail {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

@import "./mobile";
@import "./tablet";
@import "./desktop";
@import "./xl-desktop";
