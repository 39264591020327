@import "../variables";
@media screen and (min-width: 0px) {
  //   intro section
  .intro-section {
    height: 680px;

    .container {
      .intro-info {
        min-width: 100%;
        text-align: center;
        letter-spacing: 0.9px;
        padding: 0;
        outline: none;
        .intro {
          letter-spacing: 3px;
          padding-bottom: 1rem;
          font-size: 2.2rem;
        }
        .description {
          font-size: 1.5rem;
          width: 100%;
        }
      }
    }
  }

  //   about section
  .about-section {
    margin-top: 1rem;
    margin-top: 2rem;
    .about-title {
      font-size: 3rem;
      letter-spacing: 0.5px;
    }
    .about-columns {
      .about-description {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding: 0;
      }
      .skills {
        padding: 1rem 0;

        ul {
          margin: 0;
          padding-left: 0;
          padding: 0;

          grid-template-columns: repeat(4, 1fr);
          li {
            @include flex-center(center, center);
          }
        }
      }
    }
  }
  // project section
  .project-section {
    .project-section_title {
      font-size: 3rem;
      margin: 0;
      border-bottom: 1px solid #333;
    }
  }
}
