@import "../variables";
@media screen and (min-width: 1025px) {
  //    intro section
  .intro-section {
    height: 100vh;
    .container {
      @include flex-center(center, center);
      .intro-info {
        .intro {
          font-size: 4rem;
          padding: 1rem 0;
        }
        .description {
          display: inline-block;
          width: 100%;
          font-size: 2.5rem;
          letter-spacing: 3px;
          background: green;
          background: $white-color;
          color: #333;
        }
      }
    }
  }

  //   skills section
  .about-section {
    .about-title {
      font-size: 5rem;
      letter-spacing: 0.5px;
      text-align: left;
    }
    .about-columns {
      .about-description {
        margin: 0;
        @include flex-center(center, center);
        line-height: 2rem;
      }
      .skills {
        padding: 1rem 0;
        ul {
          padding: 0;
          margin: 0;
          li {
            @include flex-center(center, center);
            padding: 0.5rem;
          }
        }
      }
    }
  }

  //   project section
  .project-section {
    .project-section_title {
      font-size: 5rem;
      text-align: "left";
      border-bottom: 1px solid #333;
    }
    .project {
      .project-information {
        order: 1;
        .project-title {
          font-size: 3rem;
        }
      }
      .project-item {
        width: 100%;
      }
      .see-code {
        @include flex-center(space-between, center);
      }

      .project-stack {
        color: #333;
        font-weight: 600;
        padding: 0.75rem;
        width: 100%;
      }
      .project-description {
        font-size: 1.1rem;
      }
    }
    .project:nth-of-type(odd) {
      justify-content: space-evenly;
      .project-item {
        order: 2;
        .img-thumbnail {
          display: inline-block;
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  //   contact section
  .contact-section {
    .container {
      .contact-information {
        height: 50%;
        font-size: 1.1rem;
        @include flex-center(center, center);
        flex-direction: column;
      }
      .contact-title {
        padding-top: 1.5rem;
        font-size: 4rem;
        text-align: center;
      }
      .social-link-container {
        height: 10%;
        .social a {
          svg {
            height: 30px;
            width: 30px;
          }
        }
      }
    }
  }
}
