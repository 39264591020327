@import "../variables";

@media screen and (min-width: 1023px) {
  //    intro section
  .intro-section {
    height: 70vh;
    .container {
      @include flex-center(center, center);
      .intro-info {
        .intro {
          font-size: 3rem;
          padding: 1rem 4rem;
          letter-spacing: 3px;
        }
        .description {
          display: inline-block;
          width: 100%;
          font-size: 2.5rem;
          letter-spacing: 3px;

          text-align: center;
          background: $white-color;
          color: #333;
        }
      }
    }
  }

  //   skills section
  .about-section {
    .about-title {
      font-size: 4rem;
      letter-spacing: 0.5px;
    }
    .about-columns {
      .about-description {
        margin: 0;
        @include flex-center(center, center);
      }
      .skills {
        @include flex-center(center, center);
        ul {
          padding: 0;

          grid-template-columns: repeat(12, 1fr);

          li {
            @include flex-center(center, center);
            padding: 0.5rem;
          }
        }
      }
    }
  }

  //   project section
  .project-section {
    .project-section_title {
      font-size: 4rem;
    }
    .project {
      @include flex-direction(column);
      background: $white-color;
      padding: 2rem;
      margin-bottom: 2rem;
      .project-item {
        width: 100%;
      }
      .project-title {
        font-size: 4rem;
      }
      .see-code {
        @include flex-center(space-between, center);
      }
      .project-stack {
        color: #333;
        padding: 0.75rem;
        width: 100%;
        text-align: center;
      }
    }
  }

  //   contact section
  .contact-section {
    .container {
      .contact-information {
        height: 50%;
        @include flex-center(center, center);
        flex-direction: column;
      }
      .contact-description {
        width: 90%;
      }

      .social-link-container {
        height: 10%;
        .social a {
          svg {
            height: 30px;
            width: 30px;
          }
        }
      }
    }
  }
}
