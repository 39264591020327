@import "./variables";

.intro-section {
  @include flex-center(center, center);
  background: linear-gradient(#dfd7d7, #fff);
  width: 100%;
  .about-description {
    color: $dark-color;
    font-size: 1.2rem;
  }
  .container {
    height: 100%;
    width: 100%;
    color: $white-color;
    @include flex-center(center, center);
    flex-direction: column;

    .intro-info {
      padding: 1rem 0;
      max-width: 50%;
      font-family: $content-font-family;
      .intro {
        font-family: $font-family;
        font-weight: 100;
        color: $dark-color;
        border-bottom: 1px solid $dark-color;
      }

      .description {
        text-align: center;
        padding: 1rem 0;
        color: #333;
      }
    }
  }
}
