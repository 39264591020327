@import "./variables";

.about-section {
  @include flex-center(center, center);
  .about-title {
    width: 100%;
    letter-spacing: 0.3rem;
    text-align: left;
    border-bottom: 1px solid $dark-color;
  }
  .about-columns {
    .about-description {
      padding: 1.8rem;
      margin-right: 2rem;

      color: #333;
      font-size: 1.2rem;
      word-wrap: break-word;
      line-height: 2rem;
    }
  }
}

.about-columns .skills ul {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0.5rem;
}
.skill-heading {
  width: 80%;
  margin-bottom: 1rem;
  font-size: 3rem;
}
.about-columns .skills ul .skill-items {
  list-style: none;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.about-columns .skills ul .skill-items:nth-of-type(9) .skills-icon img,
.about-columns .skills ul .skill-items:nth-of-type(10) .skills-icon img {
  height: 48px;
  width: 48px;
}
